<template>
    <div class="share-this" v-bind:class="{'share-this_active': active}" v-on:mouseenter="handleMouseEnter"  v-on:mouseleave="handleMouseLeave" v-on:click="handleClick">
        <a class="share-this__link share-this__link_facebook" v-on:click="shareThis"></a>
        <a class="share-this__link share-this__link_vkontakte" v-on:click="shareThisVkontakte"></a>
        <a  class="share-this__link share-this__link_twitter" v-bind:href="`https://twitter.com/share?url=${overrideLink}`" target="_blank"></a>
        <!--<a href="https://twitter.com/share?ref_src=twsrc%5Etfw" data-size="large" class="twitter-share-button " data-show-count="false">Tweet</a>-->
    </div>
</template>


<script>


    export default {
        name: "ShareThis",
        props: {
            overrideLink: String,
            overrideTitle: String,
            overrideDescription: String,
            overrideImage: String
        },
        data() {
            return {
                link: this.overrideLink,
                title: this.overrideTitle || 'Romanovs100',
                description: this.overrideDescription || 'Romanovs100 is a large-scale digital photo-puzzle — a cross-platform social media project which tells the story of Russia’s last royal family through thousands of their own photos.',
                image: this.overrideImage,
                active: false
            }
        },
        methods: {
            shareThis() {

                window.FB.ui({
                        method: 'share_open_graph',
                        action_type: 'og.likes',
                        action_properties: JSON.stringify({
                            object: {
                                'og:url': this.link,
                                'og:title': this.title,
                                'og:description': this.description,
                                'og:image': this.image
                            }
                        })
                    },
                    function (response) {
                        window.console.log(response)
                    });
            },
            shareThisVkontakte() {
                let url  = '//vkontakte.ru/share.php?';
                url += 'url='          + encodeURIComponent(this.link);
                url += '&title='       + encodeURIComponent(this.title);
                url += '&description=' + encodeURIComponent(this.description);
                url += '&image='       + encodeURIComponent(this.image);
                url += '&noparse=true';
                window.open(url,'','toolbar=0,status=0,width=626,height=436');
            },
            handleMouseEnter() {
                if (window.innerWidth>1024) {
                    this.active=true;
                }
            },
            handleMouseLeave() {
                if (window.innerWidth>1024) {
                    this.active=false;
                }
            },
            handleClick() {
                this.active=!this.active
            }
        },
        mounted() {
            twttr.widgets.load()
        }
    }
</script>

<style>
    .share-this {
        cursor: pointer;
        width: 56px;
        padding-top: 56px;
        position: relative;
    }

    .share-this:after,
    .share-this:before {
        content: '';
        background: url("./../assets/img/share.png") no-repeat 50%;
        width: 56px;
        height: 56px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        visibility: visible;
        transition: all .3s ease;
    }

    .share-this:after {
        background: url("./../assets/img/share_hover.png");
        opacity: 0;
        visibility: hidden;
    }

    .share-this_active:before {
        opacity: 0;
        visibility: hidden;
    }

    .share-this_active:after {
        opacity: 1;
        visibility: visible;
    }

    .share-this__link {
        background: #000 url("./../assets/img/share_hover.png") no-repeat;
        display: block;
        width: 56px;
        height: 56px;
        opacity: 0;
        visibility: hidden;
        transform: scale(1);
        transition: all .3s ease;
    }



    .share-this_active .share-this__link {
        opacity: 1;
        visibility: visible;
    }

    .share-this__link:hover {
        transform: scale(.9);
    }

    .share-this__link_facebook {
        background-position: 0 -56px;

    }

    .share-this__link_vkontakte {
        background-position: 0 -216px;
    }

    .share-this__link_twitter {
        background-position: 0 -112px;
    }
</style>

<template>
    <div class="about">
        <a class="about__toggle" v-on:click="toggleAboutModal">ABOUT</a>
    </div>
</template>

<script>

    export default {
        data() {
            return {}
        },
        methods: {
            toggleAboutModal() {
                this.$store.dispatch('toggleAboutModal', true);
                this.$store.dispatch('changeCurrentPage', 'about');
                this.$store.dispatch('changeFullpageScroll', false);

            }
        },
        computed: {
            aboutModal() {
                return this.$store.getters.aboutModal;
            },
            currentPage() {
                return this.$store.getters.currentPage;
            }
        }

    }
</script>

<style>
    .about {
        position: fixed;
        top: 36px;
        right: 35px;
        z-index: 10;
    }

    .about__toggle {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 300;

        letter-spacing: 1.5px;
        text-align: right;
        transition: all .3s ease;
    }

    .about__toggle:hover {
        color: #846025;
    }

    @media (max-width: 767px) {
        .about {
            top: 24px;
            right: 24px;
        }
    }
</style>

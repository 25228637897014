<template>
    <div class="about-modal" v-bind:class="{'about-modal_shown': aboutModal.show}">
        <div class="about-modal__scroll">
            <div class="about-modal__wrapper">
                <div class="about-modal__section">
                    <div class="about-modal__heading">
                        About
                    </div>
                    <div class="about-modal__content">

                        <p>July 17, 2018 marked 100 years since Nicholas II, last tsar of the Russian Empire, his wife and five children were executed by the Bolsheviks. This became a focal point in the history of a country that shortly after descended into devastating civil war, paving the way for the Soviet Union. To commemorate the Romanov family, we partnered with the Russian State Archive to create #Romanovs100, an online educational narrative that mixes the visual language of photography with the digital reality of social media.</p>

                        <p>The core of #Romanovs100 is the thousands of private photographs held in Romanov family albums. These are more than just snaps. Pioneers of photography, the Romanovs owned several portable cameras and left behind abundant and detailed evidence of their everyday life.</p>

                        <p>Giving these photographs a second, digital, life we created a kind of history simulator on four social networks: Facebook, Twitter, Instagram and YouTube. The project ran for 100 days telling each family member’s story amid the chaotic events that led to the revolutions of 1917.</p>

                        <p>This website brings together the key #Romanovs100 photo center-pieces. We picked 1,000 of the best and most popular images from the collection and hope our re-telling will inspire people to learn and experience history. The images showing Nicholas II, his wife Alexandra and five children (Olga, Tatiana, Maria, Anastasia and Alexei) as a family speak for themselves.</p>

                        <div class="about-modal__more">MORE ABOUT US AT<br/> <a class="about-modal__more-link" target="_blank" href="https://romanovs100.com/">romanovs100.com</a></div>
                    </div>
                    <div class="about-modal__fig">
                        <div class="about-modal__fig-media">
                            <iframe src="https://player.vimeo.com/video/284715485?color=34004e&title=0&byline=0&portrait=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                        <div class="about-modal__fig-legend">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="about-modal__close" v-on:click="toggleAboutModal"></a>

        <a class="about-modal__logo" target="_blank" href="https://romanovs100.com/"></a>
        <div class="about-modal__bg about-modal__bg_1"></div>
        <div class="about-modal__bg about-modal__bg_2"></div>
        <div class="about-modal__bg about-modal__bg_3"></div>
        <div class="about-modal__bg about-modal__bg_4"></div>
        <div class="about-modal__bg about-modal__bg_5"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        methods: {
            toggleAboutModal() {
                this.$store.dispatch('toggleAboutModal', false);
                this.$store.dispatch('changeCurrentPage', this.$store.getters.oldPage);
                this.$store.dispatch('changeFullpageScroll', true);
            }
        },
        computed: {
            aboutModal() {
                return this.$store.getters.aboutModal;
            },
        },
        mounted() {
            if (location.hash === '#about') {
                this.$store.dispatch('toggleAboutModal', true);
            }
        }
    }
</script>

<style>

    .about-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        font-size: 3rem;
        color: #000;
        line-height: 1.5;
        font-weight: 300;
        text-align: left;
        opacity: 0;
        visibility: hidden;
        transition: all 0s ease .6s;
    }

    .about-modal__bg {
        background: #fff;
        width: 100%;
        height: 20%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translate(100%, 0);
        transition: all .3s ease .3s;
    }

    .about-modal_shown .about-modal__bg {
        transition-delay: 0s;
    }

    .about-modal__bg_2 {
        top: 20%;
        transition-delay: .45s;
    }

    .about-modal_shown .about-modal__bg_2 {
        transition-delay: .15s;
    }

    .about-modal__bg_3 {
        top: 40%;
        transition-delay: .42s;
    }

    .about-modal_shown .about-modal__bg_3 {
        transition-delay: .12s;
    }

    .about-modal__bg_4 {
        top: 60%;
        transition-delay: .5s;
    }

    .about-modal_shown .about-modal__bg_4 {
        transition-delay: .2s;
    }

    .about-modal__bg_5 {
        top: 80%;
        transition-delay: .52s;
    }

    .about-modal_shown .about-modal__bg_5 {
        transition-delay: .22s;
    }

    .about-modal_shown .about-modal__bg {
        transform: translate(0, 0);
    }

    .about-modal__scroll {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 60px);
        transition: all .3s ease;
    }

    .about-modal_shown .about-modal__scroll {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
        transition: all .3s ease .3s;

    }

    @media (max-width: 767px) {
        .about-modal {
            font-size: 1.5rem;
            line-height: 1.6;
        }
    }

    .about-modal_shown {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    .about-modal:before {
        content: '';
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        right: 16px;
        z-index: 1;
        pointer-events: none;
        transform: translate(0, -100%);
        transition: all .3s ease;
    }

    .about-modal:after {
        content: '';
        background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 16px;
        z-index: 1;
        pointer-events: none;
        transform: translate(0, 100%);
        transition: all .3s ease;
    }

    .about-modal_shown:before,
    .about-modal_shown:after {
        transform: translate(0, 0%);
        transition: all .3s ease .3s;
    }

    .about-modal__heading {
        min-width: 100%;
        flex: 1 1 auto;
        margin-bottom: 40px;
    }

    @media (max-width: 1024px) {
        .about-modal__heading {
            margin-bottom: 21px;
            order: 1;
        }
    }

    .about-modal__content {
        max-width: 50%;
        flex: 1 1 auto;
    }

    @media (max-width: 1024px) {
        .about-modal__content {
            max-width: none;
            order: 3;
        }
    }

    .about-modal__section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 118px;
    }

    .about-modal__section:last-child {
        margin: 0;
    }

    @media (max-width: 1024px) {
        .about-modal__section {
            flex-wrap: wrap;
            margin-bottom: 35px;
        }
    }

    .about-modal__list {
        display: flex;
        flex-wrap: wrap;
    }

    .about-modal__list-item {
        width: 100%;
        margin-top: 1.5em;
        max-width: 50%;
        flex: 0 1 auto;
    }

    .about-modal__list-item:nth-child(1),
    .about-modal__list-item:nth-child(2) {
        margin-top: 0;
    }

    .about-modal__wrapper {
        max-height: 100vh;
        padding: 90px 36px 36px;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        box-sizing: border-box;
    }

    @media (max-width: 767px) {
        .about-modal__wrapper {
            padding: 107px 24px 24px;
        }
    }

    .about-modal__fig {
        max-width: calc(50% - 47px);
        margin-left: 47px;
        flex: 1 1 auto;
    }

    @media (max-width: 1024px) {
        .about-modal__fig {
            order: 2;
            margin: 0 0 20px;
        }
    }

    @media (max-width: 767px) {
        .about-modal__fig {
            order: 2;
        }
    }

    .about-modal__fig-media {
        margin-bottom: 10px;
        padding-bottom: 56.25%;
        overflow: hidden;
        position: relative;
    }

    .about-modal__fig-media iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .about-modal__fig-img {
        max-width: 100%;
        vertical-align: top;
    }

    .about-modal__close {
        width: 23px;
        height: 23px;
        position: absolute;
        top: 30px;
        right: 36px;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }

    .about-modal__close:after,
    .about-modal__close:before {
        content: '';
        background: #000;
        width: 33px;
        height: 1px;
        margin: auto;
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        transform: rotate(45deg);
        transition: all .3s ease;
    }

    .about-modal__close:after {
        transform: rotate(-45deg);
    }

    .about-modal__close:hover:after,
    .about-modal__close:hover:before {
        background: #846025;
    }

    .about-modal_shown .about-modal__close {
        opacity: 1;
        visibility: visible;
        transition-delay: .2s;
    }

    @media (max-width: 767px) {
        .about-modal__close {
            top: 24px;
            right: 24px;
        }
    }

    .about-modal__logo {
        background: url("./../assets/img/logo.svg") no-repeat;
        background-size: 100%;
        width: 39px;
        height: 34px;
        margin: auto;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        z-index: 2;

        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }

    .about-modal_shown .about-modal__logo {
        opacity: 1;
        visibility: visible;
        transition-delay: .2s;
    }

    @media (max-width: 767px) {
        .about-modal__logo {
            top: 16px;
        }
    }

    .about-modal__more {
        background: url("./../assets/img/arrow.svg") no-repeat 0 50%;
        padding-left: 40px;
        position: absolute;
        bottom: 50px;
        right: 0;
        color: #aaa;
        font-size: 1.9rem;
        text-transform: uppercase;
    }

    @media (max-width: 1024px) {
        .about-modal__more {
            position: relative;
            bottom: 0;
            margin-bottom: 100px;
        }
    }

    .about-modal__more-link {
        color: #aaa;
        text-decoration: none;
    }

    .about-modal__more-link:hover {
        text-decoration: underline;
    }

</style>

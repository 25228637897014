<template>

    <div class="photo-zoom-modal" v-bind:class="{'photo-zoom-modal_shown': photoZoomModal.show}">
        <div class="photo-zoom-modal__wrapper" v-if="photoZoomModal.photo">

            <div class="photo-zoom-modal__fig">
                <img class="photo-zoom-modal__fig-img" v-bind:src="photoZoomModal.photo" alt=""/>
            </div>

        </div>
        <a class="photo-zoom-modal__close" v-on:click="togglePhotoZoomModal"></a>
    </div>

</template>

<script>

    export default {

        methods: {
            togglePhotoZoomModal() {
                this.$store.dispatch('togglePhotoZoomModal', {
                    show: false,
                    photo: null
                });
                this.photo = null;
                this.$store.dispatch('changeFullpageScroll', true);
            }
        },
        computed: {
            photoZoomModal() {
                return this.$store.getters.photoZoomModal;
            },
        }
    }
</script>

<style>
    .photo-zoom-modal {
        background: #000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 101;
        opacity: 0;
        visibility: hidden;
        transform: scale(.9);
        transition: all .3s ease;
    }

    .photo-zoom-modal_shown {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }

    .photo-zoom-modal__wrapper {
        height: calc(100vh - 72px);
        display: flex;
        padding: 36px;
        align-items: center;
    }

    .photo-zoom-modal__fig {
        width: 100%;
        max-width: 100%;
        align-self: stretch;
        flex: 1 1 auto;
        position: relative;
    }

    .photo-zoom-modal__fig-img {
        max-width: 80%;
        max-height: 80%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


    .photo-zoom-modal__close {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 109px;
        right: 109px;
        z-index: 2;
    }

    .photo-zoom-modal__close:after,
    .photo-zoom-modal__close:before {
        content: '';
        background: #fff;
        width: 1px;
        height: 50px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: rotate(45deg);

    }

    .photo-zoom-modal__close:after {
        transform: rotate(-45deg);
    }
</style>

<template>

    <div class="photo-modal" v-bind:class="{
        'photo-modal_shown': photoModal.show,
        'photo-modal_start': start,
        'photo-modal_prev-hover': prevHover,
        'photo-modal_next-hover': nextHover,
        'photo-modal_next-item-action-start': nextItemActionStart,
        'photo-modal_prev-item-action-start': prevItemActionStart,
        'photo-modal_next-item-action': nextItemAction,
        'photo-modal_prev-item-action': prevItemAction,
        'photo-modal_next-item-action-end': nextItemActionEnd,
        'photo-modal_prev-item-action-end': prevItemActionEnd,
        'photo-modal_hover': hover,

    }">
        <div class="photo-modal__wrapper" v-if="photoInfo.attributes">

            <div class="photo-modal__fig">
                <!--<a class="photo-modal__fig-link" v-on:click="togglePhotoZoomModal">-->
                <img class="photo-modal__fig-img" v-on:load="loadImg" v-bind:src="photoInfo.attributes.paths.w1280.src" v-bind:alt="photoInfo.attributes.title"/>
                <!--</a>-->

                <!--<a class="photo-modal__fig-zoom" v-on:click="togglePhotoZoomModal">CLICK TO ZOOM</a>-->
            </div>
            <div class="photo-modal__content">
                <div class="photo-modal__heading" v-if="photoInfo.attributes.title!==''">IN THE PHOTO</div>
                <div class="photo-modal__text" v-if="photoInfo.attributes.title!==''">{{photoInfo.attributes.title}}</div>
                <div class="photo-modal__heading">Tags</div>
                <div class="photo-modal__text">
                    <a class="photo-modal__text-item" v-for="(tag, index) in photoInfo.tags" :key="tag.id"
                       v-on:click="handleClickTag(tag)">{{tag.attributes.title}}<span
                            v-if="index !== (photoInfo.tags.length - 1)">, </span></a>
                </div>


            </div>


        </div>

        <div class="photo-modal__wrapper photo-modal__wrapper_prev" v-if="prevPhotoInfo.attributes">

            <div class="photo-modal__fig">
                <img class="photo-modal__fig-img" v-bind:src="prevPhotoInfo.attributes.paths.w1280.src" v-bind:alt="prevPhotoInfo.attributes.title"/>
            </div>
            <div class="photo-modal__content">
                <div class="photo-modal__heading" v-if="prevPhotoInfo.attributes.title!==''">Info</div>
                <div class="photo-modal__text" v-if="prevPhotoInfo.attributes.title!==''">{{prevPhotoInfo.attributes.title}}</div>

                <div class="photo-modal__heading">Tags</div>
                <div class="photo-modal__text">
                    <a class="photo-modal__text-item" v-for="(tag, index) in prevPhotoInfo.tags" :key="tag.id"
                       v-on:click="handleClickTag(tag)">{{tag.attributes.title}}<span
                            v-if="index !== (prevPhotoInfo.tags.length - 1)">, </span></a>
                </div>


            </div>


        </div>

        <a class="photo-modal__prev" v-on:click="prevItem" v-on:mouseover="overPrevItem" v-on:mouseout="outPrevItem" v-if="showPrev"></a>
        <img class="photo-modal__prev-fig" v-bind:src="prevPhotoInfo.attributes.paths.w1280.src" v-if="prevPhotoInfo.attributes && showPrev"/>
        <a class="photo-modal__next" v-on:click="nextItem" v-on:mouseover="overNextItem" v-on:mouseout="outNextItem" v-if="showNext"></a>
        <img class="photo-modal__next-fig" v-bind:src="nextPhotoInfo.attributes.paths.w1280.src" v-if="nextPhotoInfo.attributes && showNext"/>

        <div class="photo-modal__share" v-if="photoInfo.attributes">
            <ShareThis
                    :overrideLink="`${link}#/photos/${photoModal.id}`"
                    :overrideTitle="photoInfo.attributes.title"
                    :overrideDescription="''"
                    :overrideImage="photoInfo.attributes.paths.w1280.src"
            />
        </div>
        <a class="photo-modal__close" v-on:click="togglePhotoModal"></a>
    </div>

</template>

<script>
    import axios from 'axios';
    import ShareThis from './ShareThis'

    export default {
        data() {
            return {
                photoInfo: {},
                prevPhotoInfo: {},
                nextPhotoInfo: {},
                currentPhotoIndex: null,
                tags: [],
                link: `https://archive.romanovs100.com/`,
                nextPhotosPage: false,
                showPrev: false,
                showNext: false,
                start: false,
                prevHover: false,
                nextHover: false,
                nextItemActionStart: false,
                prevItemActionStart: false,
                nextItemAction: false,
                prevItemAction: false,
                nextItemActionEnd: false,
                prevItemActionEnd: false,
                hover: true,

            }
        },
        components: {
            ShareThis
        },
        methods: {
            getPhotoInfo() {
                const id = this.photoModal.id;
                const photos = this.photos;
                const lastPhotoIndex = photos.length;
                this.currentPhotoIndex = photos.findIndex(item => item.id === id);

                if (this.currentPhotoIndex >= 0) {
                    this.showNext = this.currentPhotoIndex < lastPhotoIndex;
                    this.showPrev = this.currentPhotoIndex !== 0;
                }

                axios.get(`/fapi/photos/${id}?include=tags`)
                    .then(res => this.setPhotoInfo(res.data))
                    .catch(console.log);
            },
            loadImg() {
                if (!this.start) {
                    setTimeout(() => {
                        this.start = true;

                    }, 300);
                }

            },
            setPhotoInfo(res) {

                this.photoInfo = {
                    ...this.photoInfo,
                    ...res.data
                };
                this.photoInfo.tags = res.included;
            },

            togglePhotoModal() {

                this.start = false;

                setTimeout(() => {
                    this.$store.dispatch('togglePhotoModal', {
                        show: false,
                        id: null
                    });
                    this.$store.dispatch('changeCurrentPage', this.$store.getters.oldPage);
                    this.$store.dispatch('changeFullpageScroll', true);
                }, 300);

            },
            /* togglePhotoZoomModal() {
                 this.$store.dispatch('togglePhotoZoomModal', {
                     show: true,
                     photo: this.photoInfo.attributes.paths.w1280.src
                 });
                 this.$store.dispatch('changeFullpageScroll', false);
             },*/
            handleClickTag(tag) {
                this.togglePhotoModal();

                const params = {
                    id: tag.id,
                    name: tag.attributes.title
                };

                this.$store.dispatch('setSelectedTag', params);
                    window.$.fn.fullpage.moveTo(3);
                    window.$('.scrolling')[0].scrollTop = 0


            },
            outPrevItem() {
                this.prevHover = false;
            },
            outNextItem() {
                this.nextHover = false;
            },
            overPrevItem() {
                this.prevHover = true;

            },
            overNextItem() {
                this.nextHover = true;
            },
            prevItem(keyboard) {
                const photos = this.photos;
                const currentPhotoIndex = this.currentPhotoIndex;
                if (currentPhotoIndex >= 0) { // найден index в массиве
                    const prevPhoto = photos[currentPhotoIndex - 1];
                    if (prevPhoto) { // если есть предыдущее фото
                        //this.showPhoto(prevPhoto.id);
                        if (!keyboard) {
                            this.prevHover = false;
                        }
                        this.prevItemActionStart = true;
                        this.$nextTick(() => {
                            setTimeout(() => {
                                this.prevItemAction = true;
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        this.photoInfo.attributes.paths.w1280.src = prevPhoto.attributes.paths.w1280.src;
                                        this.$nextTick(() => {
                                            setTimeout(() => {
                                                this.showPhoto(prevPhoto.id);
                                                this.$nextTick(() => {
                                                    setTimeout(() => {
                                                        this.prevItemActionEnd = true;

                                                        this.hover = false;
                                                        this.$nextTick(() => {
                                                            setTimeout(() => {
                                                                this.prevItemAction = false;
                                                                this.prevItemActionStart = false;
                                                                this.$nextTick(() => {
                                                                    setTimeout(() => {

                                                                        this.prevItemActionEnd = false;
                                                                        this.$nextTick(() => {
                                                                            setTimeout(() => {
                                                                                if (!keyboard) {
                                                                                    this.prevHover = true;
                                                                                }
                                                                                this.hover = true;
                                                                            }, 100);
                                                                        })
                                                                    }, 20);
                                                                });
                                                            }, 20);
                                                        }, 20);
                                                    })
                                                });
                                            }, 20);
                                        })
                                    }, 300);
                                });
                            }, 20)
                        });

                    } else { // если нет предыдущего фото запрашиваем новую партию
                        /*this.nextPhotosPage = true;
                        this.$store.dispatch('setCanNextPage', false);
                        this.$store.dispatch('setNextPage', true);
                        this.showPrev = false;*/
                    }

                } else { // не найден index в массиве
                    this.showNext = false;
                    this.showPrev = false;
                }
            },
            nextItem(keyboard) {
                const photos = this.photos;
                const photosLength = photos.length;
                const currentPhotoIndex = this.currentPhotoIndex;

                if (currentPhotoIndex >= 0 && currentPhotoIndex < photosLength) { // найден index в массиве

                    const nextPhoto = photos[currentPhotoIndex + 1];
                    if (nextPhoto) { // если есть следующее фото
                        if (!keyboard) {
                            this.nextHover = false;
                        }
                        this.nextItemActionStart = true;
                        this.$nextTick(() => {
                            this.nextItemAction = true;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.photoInfo.attributes.paths.w1280.src = nextPhoto.attributes.paths.w1280.src;
                                    this.$nextTick(() => {
                                        setTimeout(() => {
                                            this.showPhoto(nextPhoto.id);
                                            this.$nextTick(() => {
                                                setTimeout(() => {
                                                    this.nextItemActionEnd = true;
                                                    this.hover = false;

                                                    this.$nextTick(() => {
                                                        setTimeout(() => {
                                                            this.nextItemAction = false;
                                                            this.nextItemActionStart = false;


                                                            this.$nextTick(() => {
                                                                setTimeout(() => {
                                                                    this.nextItemActionEnd = false;

                                                                    this.$nextTick(() => {
                                                                        this.hover = true;

                                                                        if (!keyboard) {
                                                                            this.nextHover = true;
                                                                        }
                                                                    })
                                                                }, 20)
                                                            });
                                                        }, 20)
                                                    });
                                                }, 20)
                                            })
                                        }, 20)
                                    })
                                }, 300);
                            })
                        })
                    } else { // если нет следующего фото запрашиваем новую партию
                        this.nextPhotosPage = true;
                        this.$store.dispatch('setCanNextPage', false);
                        this.$store.dispatch('setNextPage', true);
                        this.showNext = false;
                    }
                    /*const nextPhoto = photos[currentPhotoIndex + 1];
                    if (nextPhoto) { // если есть следующее фото
                        this.showPhoto(nextPhoto.id);
                    } else { // если нет следующего фото запрашиваем новую партию
                        this.nextPhotosPage = true;
                        this.$store.dispatch('setCanNextPage', false);
                        this.$store.dispatch('setNextPage', true);
                        this.showNext = false;
                    }*/

                } else { // не найден index в массиве
                    this.showNext = false;
                    this.showPrev = false;
                }
            },
            showPhoto(id) {
                this.$store.dispatch('togglePhotoModal', {
                    show: true,
                    id
                });
                this.$store.dispatch('changeCurrentPage', `/photos/${id}`);
            }
        },

        computed: {
            photoModal() {
                return this.$store.getters.photoModal;
            },
            photos() {
                return this.$store.getters.getPhotos;
            }
        },
        watch: {
            photoModal(val, oldVal) {
                if (val.id !== oldVal.id && val.show) {
                    this.getPhotoInfo();
                } else {
                    this.photoInfo = {};
                    this.start = false;
                }
            },
            photos(newPhotos, oldPhotos) {

                if (newPhotos.length >= oldPhotos.length) {
                    if (this.nextPhotosPage) {
                        this.nextItem();
                        this.showNext = true;
                    }

                } else {
                    this.showNext = false;
                }


                this.nextPhotosPage = false;
            }
        },
        mounted() {
            document.addEventListener("keydown", (e) => {
                switch (e.keyCode) {
                    case 39: //next
                        this.nextItem(true);
                        break;
                    case 37: // prev
                        this.prevItem(true);
                        break;
                    case 27: // esc
                        this.togglePhotoModal();
                        break;
                    default:
                        break;
                }
            });
        },
        updated() {
            const photos = this.photos;
            const currentPhotoIndex = this.currentPhotoIndex;
            const photosLength = photos.length;

            if (currentPhotoIndex >= 0) { // найден index в массиве
                const prevPhoto = photos[currentPhotoIndex - 1];
                if (prevPhoto) { // если есть предыдущее фото
                    this.prevPhotoInfo = prevPhoto
                }
            }

            if (currentPhotoIndex >= 0 && currentPhotoIndex < photosLength) { // найден index в массиве
                const nextPhoto = photos[currentPhotoIndex + 1];
                if (nextPhoto) { // если есть следующее фото
                    this.nextPhotoInfo = nextPhoto
                }

            }
        }


    }
</script>

<style>
    .photo-modal {
        background: #000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 101;
        opacity: 0;
        visibility: hidden;

    }

    .photo-modal__share {
        position: absolute;
        top: 97px;
        right: 180px;
        z-index: 5;
    }

    @media (max-width: 1023px) {
        .photo-modal__share {
            right: 15px;
            top: 12px;
        }
    }

    .photo-modal_shown {
        opacity: 1;
        visibility: visible;
        transition: all 0s ease .3s;
    }

    .photo-modal__wrapper {
        height: calc(100vh - 72px);
        display: flex;
        padding: 36px;
        align-items: center;
    }

    .photo-modal__wrapper_prev {
        transform: translate(-100%, 0);
    }

    @media (max-width: 1023px) {
        .photo-modal__wrapper {
            height: calc(100vh - 130px);
            display: block;
            padding: 80px 0 50px;
            overflow: hidden;
            overflow-y: auto;
        }
    }

    .photo-modal__fig {
        align-self: stretch;
        flex: 0 1 auto;
        position: relative;
        display: flex;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 60px);
        transition: all .3s ease;
    }

    @media (max-width: 1023px) {
        .photo-modal__fig {
            margin-bottom: 20px;
        }
    }

    .photo-modal_start .photo-modal__fig {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
    }

    @media (min-width: 1025px) {
        .photo-modal_prev-hover .photo-modal__fig {
            transform: translate(90px, 0);
        }
    }

    .photo-modal_next-item-action-start .photo-modal__fig {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
        transition: all .3s ease;
    }

    .photo-modal_prev-item-action-start .photo-modal__fig {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
        transition: all .3s ease;
    }

    .photo-modal_next-item-action .photo-modal__fig {
        opacity: 0;
        visibility: hidden;
        transform: translate(-200%, 0);
    }

    .photo-modal_prev-item-action .photo-modal__fig {
        opacity: 0;
        visibility: hidden;
        transform: translate(100vw, 0);
    }

    .photo-modal_next-item-action-end .photo-modal__fig {
        transition: none;
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
    }

    .photo-modal_prev-item-action-end .photo-modal__fig {
        transition: none;
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
    }

    .photo-modal__fig-img {
        max-width: 70vw;
        max-height: calc(100vh - 172px);
    }

    @media (max-width: 1024px) {
        .photo-modal__fig-img {
            max-width: 60vw;
        }
    }

    @media (max-width: 1023px) {
        .photo-modal__fig-img {
            max-width: 100%;
            max-height: none;
        }
    }

    .photo-modal__content {
        flex: 1 1 auto;
        opacity: 0;

        visibility: hidden;
        transform: translate(0, 30px);
        transition: all .3s ease .2s;
    }

    .photo-modal_start .photo-modal__content {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        .photo-modal__content {
            padding: 0 20px;
            box-sizing: border-box;
        }
    }

    @media (min-width: 1025px) {

        .photo-modal__content {
            padding: 0 80px;
        }

        .photo-modal_next-hover .photo-modal__content {
            transform: translate(-80px, 0);
            transition: all .3s ease;
        }
    }

    .photo-modal_next-item-action-start .photo-modal__content {
        opacity: 1;
        visibility: visible;
        transition: all .3s ease;
    }

    .photo-modal_prev-item-action-start .photo-modal__content {
        opacity: 1;
        visibility: visible;
        transition: all .3s ease;
    }

    .photo-modal_next-item-action .photo-modal__content {
        opacity: 0;
        visibility: hidden;
    }

    .photo-modal_prev-item-action .photo-modal__content {
        opacity: 0;
        visibility: hidden;
    }

    .photo-modal__fig-zoom {
        position: absolute;
        bottom: 0;
        left: 0;

        font-size: 1rem;
        color: rgba(255, 255, 255, .45);
        letter-spacing: 1.11px;
        line-height: 1.3;
        text-align: left;
    }

    @media (max-width: 1023px) {
        .photo-modal__fig-zoom {
            display: none;
        }
    }

    .photo-modal__text {
        margin-bottom: 40px;
        font-size: 1.5rem;
        font-weight: 300;
        color: #fff;
        letter-spacing: 0;
        text-align: center;
        line-height: 1.8;
    }

    .photo-modal__text:last-child {
        margin: 0;
    }

    .photo-modal__heading {
        margin-bottom: 5px;
        font-size: 1rem;
        color: #846025;
        letter-spacing: 1.31px;
        text-align: center;
        line-height: 1.3;
        text-transform: uppercase;
    }

    .photo-modal__close {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 109px;
        right: 109px;
        z-index: 2;
    }

    @media (max-width: 1023px) {
        .photo-modal__close {
            margin: auto;
            top: 24px;
            left: 0;
            right: 0;
        }
    }

    .photo-modal__close:after,
    .photo-modal__close:before {
        content: '';
        background: #fff;
        width: 1px;
        height: 50px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: rotate(45deg);
        transition: all .3s ease;

    }

    .photo-modal__close:hover:after,
    .photo-modal__close:hover:before {
        background: #846025;
    }

    .photo-modal__close:after {
        transform: rotate(-45deg);
    }

    @media (max-width: 1023px) {
        .photo-modal__fig-link {
            pointer-events: none;
        }
    }

    .photo-modal__next,
    .photo-modal__prev {
        cursor: pointer;
        width: 36px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 2;
        transition: all .3s ease;
    }

    .photo-modal__next {
        left: auto;
        right: 0;
    }

    @media (min-width: 1025px) {
        .photo-modal__next:hover,
        .photo-modal__prev:hover {
            width: 85px;
        }
    }

    .photo-modal__next:before,
    .photo-modal__prev:before {
        content: 'PREV';
        color: rgba(255, 255, 255, .4);
        width: 100px;
        height: 36px;
        line-height: 36px;
        margin: auto;
        position: absolute;
        top: 0;
        left: -36px;
        bottom: 0;
        right: 0;
        transform: rotate(-90deg);
        font-size: 1rem;
        letter-spacing: 2.5px;
        text-align: center;
        transition: all .3s ease;
        z-index: 1;
    }

    @media (max-width: 1024px) {
        .photo-modal__next:before,
        .photo-modal__prev:before {
            color: rgba(255, 255, 255, 1);
        }
    }

    .photo-modal__next:before {
        content: 'NEXT';
    }



    @media (min-width: 1025px) {
        .photo-modal__next:hover:before,
        .photo-modal__prev:hover:before {
            color: rgba(255, 255, 255, 1);
            left: -8px;
        }
    }

    .photo-modal__prev-fig {
        margin: auto 0;
        position: absolute;
        top: 0;
        bottom: 0;
        max-width: 70vw;
        max-height: calc(100vh - 172px);
        visibility: hidden;
        opacity: 0;
        transform: translate(-100%, 0);

    }

    .photo-modal_hover .photo-modal__prev-fig {
        visibility: visible;
        opacity: .5;
        transition: all .3s ease .1s;
    }

    .photo-modal__next-fig {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        max-width: 70vw;
        max-height: calc(100vh - 172px);
        visibility: visible;
        opacity: .5;
        transition: all .3s ease .1s;
    }

    @media (max-width: 1024px) {
        .photo-modal__prev-fig,
        .photo-modal__next-fig {
            max-width: 70vw;
        }
    }

    @media (max-width: 1023px) {
        .photo-modal__prev-fig,
        .photo-modal__next-fig {
            max-width: 100%;
            max-height: none;
            top: 80px;
            bottom: auto;
        }
    }

    @media (min-width: 1025px) {
        .photo-modal_prev-hover .photo-modal__prev-fig {
            transform: translate(calc(-100% + 80px), 0);
        }

        .photo-modal_next-hover .photo-modal__next-fig {
            transform: translate(-80px, 0);
        }
    }

    .photo-modal_next-item-action-start .photo-modal__next-fig {
        transition: all .3s ease;
    }

    .photo-modal_prev-item-action-start .photo-modal__prev-fig {
        transition: all .3s ease;
    }

    .photo-modal_next-item-action .photo-modal__next-fig {
        left: 36px;
        opacity: 1;
        transform: translate(0, 0);
    }

    .photo-modal_prev-item-action .photo-modal__prev-fig {
        left: 36px;
        opacity: 1;
        transform: translate(0, 0);
    }

    @media (max-width: 1024px) {
        .photo-modal_prev-item-action .photo-modal__prev-fig,
        .photo-modal_next-item-action .photo-modal__next-fig {
            left: 0;
        }
    }

    .photo-modal_next-item-action-end .photo-modal__next-fig {
        left: 100%;
        transition: none;
        opacity: .5;
        transform: translate(0, 0);
    }

    .photo-modal_prev-item-action-end .photo-modal__prev-fig {
        left: auto;
        transition: none;
        transform: translate(-100%, 0);
    }

    @media (max-width: 1023px) {
        .photo-modal__content {
            width: 100%;
        }

        .photo-modal__fig {
            width: 100%;
            text-align: center;
        }
    }

</style>

<template>

    <div>
        <div class="center">
            <p>The Romanovs were pioneers of private photography — in the early 20th century they used some of the world’s first portable Kodak cameras to capture almost every meaningful event in their lives.</p>
        </div>



    </div>


</template>

<script>
    export default {
        data() {
            return {}
        }


    }
</script>

<style scoped>
    .center {
        overflow: hidden;
        width: 823px;
        padding: 50px 0 50px;
        margin: 0 auto 8vh;
        position: relative;
        font-size: 4rem;
        color: #fff;
        text-align: center;
        line-height: 1.3;
    }

    @media (max-width: 1023px) {
        .center {
            width: 80vw;

            font-size: 3rem;
        }
    }

    @media (max-width: 767px) {
        .center {
            font-size: 2rem;
            line-height: 1.6;
        }
    }

</style>

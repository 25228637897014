<template>
    <div>
        <div class="photos-list" v-if="photos.length">
            <div class="photos-list__item"
                 v-for="(photo, index) in photos"
                 v-bind:class="{
                    'photos-list__item_long': photo.attributes.paths.h305.width > 600,
                    'photos-list__item_middle': photo.attributes.paths.h305.width >= 350 && photo.attributes.paths.h305.width <= 600,
                    'photos-list__item_small': photo.attributes.paths.h305.width < 350
                 }"
                 :data-id="photo.id"
                 v-bind:key="photo.id"
            >
                <img
                        v-bind:id="`photos-list-img-${photo.id}`"
                        class="photos-list__img"
                        v-bind:style="{'animation-delay': `${index%perPage/10}s`}"
                        v-on:click="togglePhotoModal(photo.id)"
                        v-bind:src="photo.attributes.paths.h305.src"
                        v-bind:alt="photo.attributes.title"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            let {innerWidth, innerHeight} = window;
            return {
                currentPage: 1,
                pageCount: 1,
                perPage: 20,
                totalCount: 0,
                windowWidth: innerWidth,
                windowHeight: innerHeight,
            }
        },
        methods: {
            getPhotos(changedCategory) {
                const {id: tag} = this.selectedTag;

                const params = {
                    page: this.currentPage,
                    "per-page": this.perPage,
                };

                if (tag) {
                    params["filter[tagIds]"] = tag
                }

                axios.get('/fapi/photos', {
                    params
                })
                    .then(res => this.setPhotos(res.data, changedCategory))
                    .catch(console.log);
            },
            recalcPerPage() {
                if (this.windowWidth > 1920 || this.windowHeight > 1200) {
                    this.perPage = 40;
                } else {
                    this.perPage = 30;
                }
            },
            rebuildFullpage() {
                window.$.fn.fullpage.reBuild();
            },
            setPhotos(res, changedCategory) {

                const {['current-page']: currentPage, ['page-count']: pageCount, ['per-page']: perPage, ['total-count']: totalCount} = res.meta;
                let photos = res.data;

                this.$store.dispatch('addPhotos', {
                    photos,
                    changedCategory
                });

                this.currentPage = currentPage;
                this.pageCount = pageCount;
                this.perPage = perPage;
                this.totalCount = totalCount;


            },
            togglePhotoModal(id) {
                this.$store.dispatch('togglePhotoModal', {
                    show: true,
                    id
                });

                this.$store.dispatch('changeCurrentPage', `/photos/${id}`);

                this.$store.dispatch('changeFullpageScroll', false);

            },
            setNextPage() {
                this.$store.dispatch('setNextPage', false);
                this.$store.dispatch('setCanNextPage', false);

                if (this.currentPage < this.pageCount) {
                    this.currentPage++;
                    this.getPhotos();
                }
            }
        },

        computed: {
            selectedTag() {
                return this.$store.getters.selectedTag;
            },
            nextPage() {
                return this.$store.getters.nextPage;
            },
            photos() {
                return this.$store.getters.getPhotos;
            }
        },
        watch: {
            selectedTag(val, oldVal) {
                if (val.id !== oldVal.id) {
                    this.currentPage = 1;
                    this.getPhotos(true);

                }
            },
            nextPage(val) {
                if (val) {
                    this.setNextPage()
                }
            },
            windowWidth(newWidth, oldWidth) {
                if (newWidth !== oldWidth) {
                    this.rebuildFullpage();
                    this.recalcPerPage();

                }
            },
            perPage(newPerPage, oldPerPage) {
                if (newPerPage !== oldPerPage) {

                    this.getPhotos();
                }
            }
        },

        mounted() {
            this.recalcPerPage();

            if (this.perPage === 20) {
                this.getPhotos();
            }

            this.$nextTick(() => {
                let windowResizeTimeout;
                window.addEventListener('resize', () => {
                    clearTimeout(windowResizeTimeout);

                    windowResizeTimeout = setTimeout(() => {
                        let {innerWidth, innerHeight} = window;
                        this.windowWidth = innerWidth;
                        this.windowHeight = innerHeight;
                    }, 300);
                });
            });

            let {hash} = location;

            if (hash && hash.indexOf('/photos/') > -1) {
                let id = hash.replace('#/photos/', '');
                setTimeout(() => {
                    this.togglePhotoModal(id);
                }, 300)
            }
        },
        updated() {
            setTimeout(() => {
                this.$store.dispatch('setCanNextPage', true);
            }, 1000);
        }

    }
</script>

<style>
    .photos-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 100px 28px 36px;
        transition: all .3s ease;

    }

    @media (min-width: 1025px) {
        .page_with-photo-modal .photos-list {
            transform: translate(0, 60px) scale(.95);
            opacity: 0;
        }
    }

    .photos-list__item {
        height: 305px;
        margin: 12px;
        flex: 0 1 auto;
        position: relative;

    }

   /* .photos-list__item_long {
        flex: 3 1 33%;
    }

    .photos-list__item_small {
        flex: 0 1 auto;
    }*/

    .photos-list__item_middle .photos-list__img,
    .photos-list__item_small .photos-list__img {
        object-fit: none;
    }

    .photos-list__img {
        cursor: pointer;
        display: block;
        width: 100%;
        height: 305px;
        object-fit: cover;
        animation: photo .3s ease both;
    }

    @media (max-width: 1023px) {
        .photos-list__item_small,
        .photos-list__item_middle {
            max-width: calc(50% - 24px);
        }
    }

    @media (max-width: 767px) {
        .photos-list {
            padding: 46px 6px;
        }

        .photos-list__item {
            height: 125px;
            margin: 6px;
        }


        .photos-list__item_middle .photos-list__img,
        .photos-list__item_small .photos-list__img,
        .photos-list__img {
            height: 125px;
            margin: auto;
            object-fit: cover;
        }
    }

    @keyframes photo {
        0% {
            opacity: 0;
            transform: translate(0, 10%);
        }
        100% {
            opacity: 1;
            transform: translate(0, 0);
        }
    }


</style>

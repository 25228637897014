import { render, staticRenderFns } from "./PhotoZoomModal.vue?vue&type=template&id=479e6aa6&"
import script from "./PhotoZoomModal.vue?vue&type=script&lang=js&"
export * from "./PhotoZoomModal.vue?vue&type=script&lang=js&"
import style0 from "./PhotoZoomModal.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "PhotoZoomModal.vue"
export default component.exports
<template>
    <div>
        <Header/>
        <FullPage />
        <Footer/>
        <AboutModal/>
        <MenuModal/>
        <PhotoModal/>
        <PhotoZoomModal/>
    </div>
</template>

<script>

    import Header from './Header.vue';
    import Footer from './Footer.vue';
    import AboutModal from './AboutModal.vue';
    import MenuModal from './MenuModal.vue';
    import PhotoModal from './PhotoModal.vue';
    import PhotoZoomModal from './PhotoZoomModal.vue';
    import FullPage from './FullPage.vue';

    export default {
        name: 'app',
        data() {
            return {}
        },
        components: {
            Header,
            Footer,
            AboutModal,
            MenuModal,
            PhotoModal,
            PhotoZoomModal,
            FullPage
        }
    }
</script>

<style>
    #app {
        min-width: 320px;
    }
</style>

<template>
    <div id="fullpage">
        <div class="section section_1 active">
            <Slide1/>
        </div>
        <div class="section section_2">
            <Slide2/>
        </div>
        <div class="section section_3">
            <div class="scrolling">
            <Slide3/>
            </div>
        </div>

    </div>
</template>

<script>
    import Slide1 from './Slide1.vue';
    import Slide2 from './Slide2.vue';
    import Slide3 from './Slide3.vue';

    export default {
        name: "FullPage",
        data() {
            let {innerWidth, innerHeight} = window;
            return {
                windowWidth: innerWidth,
                windowHeight: innerHeight,
                windowScrollTop: 0,
                canSetWindowScrollTop: true
            }
        },
        components: {
            Slide1,
            Slide2,
            Slide3,
        },
        methods: {
            rebuildFullpageScroll(state) {
                window.$.fn.fullpage.setAllowScrolling(state);
                window.$.fn.fullpage.setMouseWheelScrolling(state);
                window.$.fn.fullpage.setKeyboardScrolling(state);
            },

            getNextPage() {
                if (this.canNextPage) {
                    this.$store.dispatch('setCanNextPage', false);
                    this.$store.dispatch('setNextPage', true);
                }
            },

            disableInfiniteWindowScroll() {
                let $window = window.$(window);
                $window.off('scroll.fullpage')
            },

            initInfiniteWindowScroll() {
                let self = this;
                let $window = window.$(window);
                let $document = window.$(document);

                let windowScrollTop = 0;
                let windowScrollTopWithHeight = 0;
                let windowHeight = $window.height();

                $window.off('scroll.fullpage').on('scroll.fullpage', function (e) {
                    e.stopPropagation();

                    windowScrollTop = $window.scrollTop();

                    windowScrollTopWithHeight = windowScrollTop + windowHeight;


                    if ( windowScrollTopWithHeight > $document.height() - windowHeight) {
                        if (self.canSetWindowScrollTop) {
                            self.windowScrollTop = windowScrollTop;
                            self.canSetWindowScrollTop = false;
                        }
                        self.getNextPage();



                    }
                });
            },

            initInfiniteFullpageScroll() {
                let $scroller = window.$('.scrolling');
                let self = this;

                if (!$scroller.length) {
                    return
                }

                let windowHeight = $(window).height();

                $scroller.off('wheel.fullpage mousewheel.fullpage').on('wheel.fullpage mousewheel.fullpage', function (e) {
                    e.stopPropagation();


                    var oEvent = e.originalEvent,
                        delta = oEvent.deltaY || oEvent.wheelDelta;

                    if (delta > 0) {
                        if (($scroller.scrollTop() + $scroller.height()) >= $scroller.prop('scrollHeight') - windowHeight) {
                            self.getNextPage();
                        }
                    } else {
                        if ($scroller.scrollTop() <= 0) {
                            window.$.fn.fullpage.moveTo(2);
                        }
                    }
                });
            },

            disableInfiniteFullpageScroll() {
                let $scroller = window.$('.scrolling');

                if (!$scroller.length) {
                    return
                }

                $scroller.off('wheel.fullpage mousewheel.fullpage');
            },

            initFullPage() {
                let self = this;
                window.$('#fullpage').fullpage({
                    anchors: ['main', 'photography', 'photos'],
                    navigation: false,
                    sectionsColor: ['#000', '#000', '#000'],
                    responsiveWidth: 767,
                    afterResponsive: function(isResponsive){
                    },
                    afterRender: function () {
                    },
                    afterLoad: function () {
                        self.$store.dispatch('changeCurrentPage', window.$.fn.fullpage.getActiveSection().anchor);

                        // crome bug
                        let $fbTableCell = window.$('.fp-tableCell');
                        let fbTableCellHeight = $fbTableCell.css('height');
                        $fbTableCell.css('height', '');
                        $fbTableCell.css('height', fbTableCellHeight);

                    }
                });
            }


        },
        computed: {
            fullpageScroll() {
                return this.$store.getters.fullpageScroll;
            },
            nextPage() {
                return this.$store.getters.nextPage;
            },
            canNextPage() {
                return this.$store.getters.canNextPage;
            }
        },
        watch: {
            windowWidth(newWidth, oldWidth) {
                if (newWidth !== oldWidth) {
                    if (newWidth < 768) {
                        this.disableInfiniteFullpageScroll();
                        this.initInfiniteWindowScroll();
                    } else {
                        this.disableInfiniteWindowScroll();
                        this.initInfiniteFullpageScroll();
                    }

                }
            },
            canNextPage(newCan, oldCan) {}
        },
        mounted() {
            this.initFullPage();
            let {innerWidth} = window;

            if (innerWidth < 768) {
                this.initInfiniteWindowScroll();
            } else {
                this.initInfiniteFullpageScroll();
            }



            this.$nextTick(() => {
                let windowResizeTimeout;
                window.addEventListener('resize', () => {
                    clearTimeout(windowResizeTimeout);

                    windowResizeTimeout = setTimeout(() => {
                        let {innerWidth, innerHeight} = window;
                        this.windowWidth = innerWidth;
                        this.windowHeight = innerHeight;
                    }, 300);
                });


            });
        },
        updated() {
        }
    }
</script>


<style>
    .section {
        position: relative;
    }

    .page_with-photo-modal #fullpage {
        pointer-events: none;
    }

    @media (max-width: 767px) {



        .fullpage-wrapper {
            height: auto!important;
            transform: none!important;
        }
        html, body {
            height: auto!important;
            overflow: visible!important;
        }

        .section {
            min-height: 100vh;
        }

        .section_2 {
            min-height: 0;
        }
        .fp-section.fp-table,
        .fp-slide.fp-table,
        .fp-tableCell {
            display: block!important;
            height: auto!important;
        }
    }



    .scrolling {
        max-height: 100vh;
        height: inherit;
        overflow: hidden;
        position: relative;
        transform: translate(0, calc(-10vh - 150px));
        transition: transform .3s ease;
    }

    .fp-viewing-photos .scrolling {
        transform: translate(0, 0);
        overflow-y: scroll;
    }

    @media (max-width: 767px) {
        .fp-viewing-photos .scrolling,
        .scrolling {
            transform: translate(0, 0);
            max-height: none;
            overflow: visible;
        }
    }

    @media (min-width: 768px) {
        .scrolling::-webkit-scrollbar {
            width: 7px;
        }

        .scrolling::-webkit-scrollbar-track {
            background: none;
            border: 0;
            box-shadow: none;
        }

        .scrolling::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border: 0;
            box-shadow: none;

            box-sizing: border-box;
            position: absolute;


            border-radius: 3px;

        }
    }



</style>

<template>
    <div class="sound">
        <a class="sound__toggle" v-on:click="togglePlay">
            <span v-if="play">ON</span>
            <span v-if="!play">OFF</span>
        </a>
        <div ref="bg">
            <audio id="playAudio" ref="playAudio" data-autoplay autoplay loop v-if="!isChrome">
                <source :src="audioSrc" type="audio/mpeg"/>
            </audio>
            <iframe id="iframeAudio" ref="iframeAudio" :src="audioSrc" allow="autoplay" style="display:none" v-if="isChrome && canPlay"></iframe>
        </div>
    </div>
</template>

<script>


    export default {
        data() {
            return {
                play: true,
                isChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
                audioSrc: '//cdn.rt.com/spprojects/1917live/ost-rotor.mp3',
                audioDuration: null,
                audioFrameTimeout: null,
                canPlay: true
            }
        },
        methods: {
            togglePlay() {
                this.play = !this.play;

                if (this.isChrome) {
                    this.canPlay = this.play;
                } else {
                    if (this.play) {
                        this.$refs.playAudio.play();
                    } else {
                        this.$refs.playAudio.pause();
                    }
                }
            },
            loopAudioFrame() {
                clearTimeout(this.audioFrameTimeout);
                this.audioFrameTimeout = setTimeout(() => {
                    this.canPlay = false;
                    setTimeout(() => {
                        this.canPlay = true;
                        this.loopAudioFrame();
                    }, 100);
                }, this.audioDuration * 1000 + 444);
            }
        },
        mounted() {
            if (this.isChrome) {
                let audioFile = new Audio();
                audioFile.src = this.audioSrc;

                audioFile.addEventListener('loadedmetadata', () => {
                    this.audioDuration = audioFile.duration;
                    this.loopAudioFrame();
                });
            }
        }
    }
</script>

<style>
    .sound {
        position: absolute;
        bottom: 24px;
        right: 36px;
        z-index: 2;
    }

    @media (max-width: 767px) {
        .sound {
            right: 24px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
        }

        .page_scrolled .sound {
            opacity: 1;
            visibility: visible;
        }
    }

    .sound__toggle {
        padding-right: 20px;
        font-size: 1.1rem;
        color: #fff;
        letter-spacing: 1.1px;
        text-align: right;
        transition: all .3s ease;
    }

    .sound__toggle:hover {
        color: #846025;
    }

    .sound__toggle:after,
    .sound__toggle:before {
        content: '';
        background: url("./../assets/img/sound.svg") no-repeat;
        background-size: 100%;
        width: 14px;
        height: 20px;
        margin: auto;
        position: absolute;
        top: -10px;
        bottom: -10px;
        right: 0;
        opacity: 1;
        visibility: visible;
        transition: all .3s ease;
    }

    .sound__toggle:after {
        background-image: url("./../assets/img/sound_hover.svg");
        opacity: 0;
        visibility: hidden;
    }

    .sound__toggle:hover:before {
        opacity: 0;
        visibility: hidden;
    }

    .sound__toggle:hover:after {
        opacity: 1;
        visibility: visible;
    }
</style>

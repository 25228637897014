<template>
    <div class="menu">
        <a class="menu__toggle" v-bind:class="{'menu__toggle_dark': aboutModal.show, 'menu__toggle_show': menuModal.show}" v-on:click="toggleMenuModal">
            <span class="menu__toggle-text" v-bind:class="{'menu__toggle-text_show': menuModal.show}"></span>
            <span class="menu__toggle-text menu__toggle-text_tag" v-bind:class="{'menu__toggle-text_show': !menuModal.show}" v-if="selectedTag.name">{{selectedTag.name}}</span>
        </a>
    </div>
</template>

<script>


    export default {
        data() {
            return {}
        },
        methods: {
            toggleMenuModal() {
                this.$store.dispatch('toggleMenuModal', !this.menuModal.show);
                if (this.menuModal.show) {
                    this.$store.dispatch('changeFullpageScroll', false);
                } else {
                    this.$store.dispatch('changeFullpageScroll', true);
                }
            }
        },
        computed: {
            aboutModal() {
                return this.$store.getters.aboutModal;
            },
            menuModal() {
                return this.$store.getters.menuModal;
            },
            selectedTag() {
                return this.$store.getters.selectedTag;
            }
        }
    }
</script>

<style>
    .menu {
        position: fixed;
        top: 33px;
        left: 40px;
        z-index: 101;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }

    .fp-viewing-photos .menu,
    .fp-viewing-photography .menu,
    .page_scrolled .menu {
        opacity: 1;
        visibility: visible;
    }

    .menu__toggle {
        display: block;
        position: relative;
        padding-left: 50px;
        height: 19px;
    }

    .menu__toggle-text {
        display: block;
        position: absolute;
        top: -5px;
        font-size: 3rem;
        font-weight: 300;
        color: #fff;
        letter-spacing: 1px;
        line-height: 1;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transform: translate(-60%, 0);
        transition: all .3s ease;
    }

    .menu__toggle-text:hover {
        color: #000;
    }

    @media (max-width: 767px) {
        .menu {
            top: 24px;
            left: 24px;
        }

        .menu__toggle {
            padding-left: 40px;
        }

        .menu__toggle-text {
            top: 0;
            font-size: 2rem;
        }
    }

    .menu__toggle-text_show {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
    }

    .menu__toggle:before {
        content: '';
        background: #fff;
        box-shadow: 0 9px 0 #fff;
        width: 31px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .3s ease;
    }

    .menu__toggle:hover:before {
        background: #846025;
        box-shadow: 0 9px 0 #846025;
    }

    .menu__toggle_show:before {
        box-shadow: none;
        transform: rotate(45deg) translate(0, 13px);
    }

    .menu__toggle_show:hover:before {
        background: #000;
    }

    .menu__toggle:after {
        content: '';
        background: #fff;
        width: 14px;
        height: 1px;
        position: absolute;
        top: 18px;
        left: 0;
        transition: all .3s ease;
    }

    .menu__toggle:hover:after {
        background: #846025;
    }

    .menu__toggle_show:after {
        width: 31px;
        transform: rotate(-45deg) translate(0, -13px);
    }

    .menu__toggle_show:hover:after {
        background: #000;
    }

    .menu__toggle_dark:before {
        background: #000;
        box-shadow: 0 9px 0 #000;
    }

    .menu__toggle_dark:hover:before {
        background: #846025;
        box-shadow: 0 9px 0 #846025;
    }

    .menu__toggle_show.menu__toggle_dark:before {
        background: #fff;
        box-shadow: none;
    }

    .menu__toggle_dark:after {
        background: #000;
    }

    .menu__toggle_show.menu__toggle_dark:after {
        background: #fff;
    }

    @media (max-width: 767px) {
        .menu__toggle-text_tag {
            display: none;
        }
    }


</style>

import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        aboutModal: {
            show: false
        },
        menuModal: {
            show: false
        },
        photoModal: {
            show: false,
            id: null
        },
        photoZoomModal: {
            show: false,
            photo: null
        },
        selectedTag: {
            id: null,
            name: null
        },
        photos: [],
        nextPage: false,
        canNextPage: true,
        currentPage: location.hash.length ? location.hash.replace('#', '') : 'main',
        oldPage: 'main',
        fullpageScroll: true
    },
    actions: {
        toggleAboutModal({commit}, comment) {
            commit('TOGGLE_ABOUT_MODAL', comment)
        },
        toggleMenuModal({commit}, comment) {
            commit('TOGGLE_MENU_MODAL', comment)
        },
        togglePhotoModal({commit}, comment) {
            commit('TOGGLE_PHOTO_MODAL', comment)
        },
        togglePhotoZoomModal({commit}, comment) {
            commit('TOGGLE_PHOTO_ZOOM_MODAL', comment)
        },
        setSelectedTag({commit}, comment) {
            commit('SET_SELECTED_TAG', comment)
        },
        setNextPage({commit}, comment) {
            commit('SET_NEXT_PAGE', comment)
        },
        setCanNextPage({commit}, comment) {
            commit('SET_CAN_NEXT_PAGE', comment)
        },
        changeCurrentPage({commit}, comment) {
            commit('CHANGE_CURRENT_PAGE', comment)
        },
        changeOldPage({commit}, comment) {
            commit('CHANGE_OLD_PAGE', comment)
        },
        changeFullpageScroll({commit}, comment) {
            commit('CHANGE_FULL_PAGE_SCROLL', comment)
        },
        addPhotos({commit}, comment) {
            commit('ADD_PHOTOS', comment)
        }

    },
    mutations: {
        TOGGLE_ABOUT_MODAL(state, comment) {
            state.aboutModal.show = comment;
        },
        TOGGLE_MENU_MODAL(state, comment) {
            state.menuModal.show = comment;
        },
        TOGGLE_PHOTO_MODAL(state, comment) {
            state.photoModal = comment;
        },
        TOGGLE_PHOTO_ZOOM_MODAL(state, comment) {
            state.photoZoomModal = comment;
        },
        SET_SELECTED_TAG(state, comment) {
            state.selectedTag = comment;
        },
        SET_NEXT_PAGE(state, comment) {
            state.nextPage = comment;
        },
        SET_CAN_NEXT_PAGE(state, comment) {
            state.canNextPage = comment;
        },
        CHANGE_CURRENT_PAGE(state, comment) {
            state.currentPage = comment;
        },
        CHANGE_OLD_PAGE(state, comment) {
            state.oldPage = comment;
        },
        CHANGE_FULL_PAGE_SCROLL(state, comment) {
            state.fullpageScroll = comment;
        },
        ADD_PHOTOS(state, {photos, changedCategory}) {
            if (changedCategory) {
                state.photos = photos;
            } else {

                state.photos = [...state.photos, ...photos];

                state.photos = state.photos.filter((photo, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === photo.id
                    ))
                )
            }
        }
    },
    getters: {
        aboutModal(state) {
            return state.aboutModal;
        },
        menuModal(state) {
            return state.menuModal;
        },
        photoModal(state) {
            return state.photoModal;
        },
        photoZoomModal(state) {
            return state.photoZoomModal;
        },
        selectedTag(state) {
            return state.selectedTag;
        },
        nextPage(state) {
            return state.nextPage;
        },
        canNextPage(state) {
            return state.canNextPage;
        },
        currentPage(state) {
            return state.currentPage;
        },
        oldPage(state) {
            return state.oldPage;
        },
        fullpageScroll(state) {
            return state.fullpageScroll;
        },
        getPhotos(state) {
            return state.photos;
        }
    }
});
export default store

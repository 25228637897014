<template>

    <div>


        <PhotosList/>

    </div>


</template>

<script>
    import PhotosList from './PhotosList'
    export default {
        data() {
            return {}
        },
        components: {
            PhotosList
        }

    }
</script>

<style scoped>


</style>

<template>

    <div class="wrapper">
        <div class="center">
            <a class="logo" target="_blank" href="https://romanovs100.com/"></a>
            <div class="hashtag">#ROMANOVS100</div>
            <div class="about_site">4,000 PHOTOS<br/> 4 SOCIAL NETWORKS<br/> 1 FAMILY</div>
        </div>
        <div class="mouse"></div>
        <div class="headphones">
            Turn UP YOUR<br/> SPEAKERS
        </div>
        <div class="bg">
            <video class="video" loop  muted autoplay data-autoplay playsinline src="//cdn.rt.com/spprojects/1917live/bg-teasers.mp4"></video>
        </div>
    </div>


</template>

<script>

    export default {
        data() {
            return {
            }
        }
    }
</script>

<style scoped>

    .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .bg {
        pointer-events: none;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        bottom: 0;
        z-index: -1;
        opacity: .15;
    }

    .video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;

    }

    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (max-height: 800px) and (min-width: 1024px) {
        .center {
            transform: translate(-50%, -60%);
        }
    }

    @media (max-width: 767px) {
        .center {
            left: 24px;
            right: 24px;
            transform: translate(0, -50%);
        }
    }

    .logo {
        background: url("./../assets/img/logo.svg") no-repeat;
        background-size: 100%;
        display: block;
        width: 166px;
        height: 144px;
        margin: 0 auto 63px;
    }

    @media (max-height: 800px) {
        .logo {
            width: 83px;
            height: 72px;
            margin-bottom: 31px;
        }
    }

    @media (max-width: 767px) {
        .logo {
            background-size: 100% auto;
            width: 88px;
            height: 76px;
            margin-bottom: 15px;
        }
    }

    .hashtag {
        margin-bottom: 53px;
        font-size: 7.5rem;
        color: #fff;
        letter-spacing: 5.09px;
        text-align: center;
        line-height: .9;
    }

    @media (max-height: 800px) {
        .hashtag {
            margin-bottom: 21px;
            font-size: 3.7rem;
            letter-spacing: 2.59px;
        }
    }

    @media (max-width: 767px) {
        .hashtag {
            margin-bottom: 6px;
            font-size: 2.3rem;
            letter-spacing:1.87px;

        }
    }

    .about_site {
        font-size: 3rem;
        color: #846025;
        letter-spacing: 2.12px;
        text-align: center;
        line-height: 1.6;
    }

    @media (max-height: 800px) {
        .about_site {
            font-size: 1.5rem;
        }
    }

    @media (max-width: 767px) {
        .about_site {
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
    }

    .mouse {
        border: 1px solid #fff;
        border-radius: 100px;
        width: 22px;
        height: 33px;
        margin: auto;
        position: absolute;
        bottom: 51px;
        left: 0;
        right: 0;
    }

    @media (max-width: 1024px) {
        .mouse {
            display: none;
        }
    }

    .mouse:before {
        content: '';
        background: #fff;
        border-radius: 100px;
        width: 4px;
        height: 9px;
        margin: auto;
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
    }

    .headphones {
        background: url("./../assets/img/headphones.svg") no-repeat 50% 0;
        padding-top: 28px;
        width: 171px;
        margin: auto;
        position: absolute;
        z-index: 2;
        top: 80px;
        left: 0;
        right: 0;
        color: #fff;
        font-size: 1rem;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
    }

    @media (max-width: 767px) {
        .headphones {
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
        }

        .page_scrolled .headphones {
            opacity: 1;
            visibility: visible;
        }
    }
</style>

<template>
    <div class="header">
        <Menu />
        <About />
        <a class="header__logo" target="_blank" href="https://romanovs100.com/"></a>
    </div>
</template>

<script>

    import About from './About';
    import Menu from './Menu';

    export default {
        data() {
            return {}
        },
        components: {
            About,
            Menu
        }

    }
</script>

<style>
    .header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .header:before {
        content: '';
        background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
        height: 100px;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 1;

        transition: all .3s ease;
        pointer-events: none;
    }

    @media (max-width: 767px) {
        .header:before {
            right: 0;
        }
    }



    .header__logo {
        background: url('./../assets/img/logo.svg') no-repeat;
        background-size: 100%;
        width: 39px;
        height: 34px;
        margin: auto;
        position: fixed;
        top: 26px;
        left: 0;
        right: 0;
        z-index: 11;
        opacity: 1;
        visibility: visible;
        transition: all .3s ease .3s;

    }

    @media (max-width: 767px) {
        .header__logo {
            top: 16px;
        }
    }

    .fp-viewing-main .header__logo {
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

    }


</style>

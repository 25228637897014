<template>
    <div class="menu-modal" v-bind:class="{'menu-modal_shown': menuModal.show}">
        <div class="menu-modal__wrapper">
            <div class="menu-modal__list">
                <div class="menu-modal__list-item">
                    <a class="menu-modal__list-link" v-on:click="selectTag()">ALL</a>
                </div>
                <div class="menu-modal__list-item" v-for="tag in tags" :key="tag.id">
                    <a class="menu-modal__list-link" v-on:click="selectTag(tag)">{{tag.attributes.title}}</a>
                </div>
            </div>
        </div>
        <a class="menu-modal__close" v-on:click="toggleMenuModal"></a>

        <div class="menu-modal__logo"></div>

        <div class="menu-modal__bg menu-modal__bg_1"></div>
        <div class="menu-modal__bg menu-modal__bg_2"></div>
        <div class="menu-modal__bg menu-modal__bg_3"></div>
        <div class="menu-modal__bg menu-modal__bg_4"></div>
        <div class="menu-modal__bg menu-modal__bg_5"></div>
    </div>
</template>

<script>

    import axios from 'axios';

    export default {
        data() {
            return {
                tags: []
            }
        },
        methods: {
            getTags() {
                axios.get(`/fapi/tags`)
                    .then(res => this.setTags(res.data))
                    .catch(console.log);
            },
            setTags(res) {
                this.tags = res.data;
            },
            selectTag(tag) {


                let params = {
                    id: null,
                    name: null
                };

                if (tag) {
                    params = {
                        id: tag.id,
                        name: tag.attributes.title
                    }
                }

                this.$store.dispatch('setSelectedTag', params);
                this.toggleMenuModal();
                window.$.fn.fullpage.moveTo(3)
            },
            toggleMenuModal() {
                this.$store.dispatch('toggleMenuModal', false);
                this.$store.dispatch('changeFullpageScroll', true);
            }
        },
        computed: {
            menuModal() {
                return this.$store.getters.menuModal;
            },
        },
        mounted() {
            this.getTags();
        }

    }
</script>

<style>

    .menu-modal {

        width: 100%;
        height: 100%;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;

        font-size: 3rem;
        color: #000;
        line-height: 1.5;
        font-weight: 300;
        text-align: left;

        opacity: 0;
        visibility: hidden;
        transition: all 0s ease .6s;

    }

    .menu-modal:after {
        content: '';
        background:  url("./../assets/img/crown.png") no-repeat -6px -381px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease .3s;
    }

    .menu-modal_shown:after {
        opacity: 1;
        visibility: visible;
    }

    .menu-modal__bg {
        background: #846025;
        width: 100%;
        height: 20%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translate(-100%, 0);
        transition: all .3s ease .3s;
    }

    .menu-modal_shown .menu-modal__bg {
        transition-delay: 0s;
    }

    .menu-modal__bg_2 {
        top: 20%;
        transition-delay: .45s;
    }

    .menu-modal_shown .menu-modal__bg_2 {
        transition-delay: .15s;
    }

    .menu-modal__bg_3 {
        top: 40%;
        transition-delay: .42s;

    }

    .menu-modal_shown .menu-modal__bg_3 {
        transition-delay: .12s;
    }

    .menu-modal__bg_4 {
        top: 60%;
        transition-delay: .5s;
    }

    .menu-modal_shown .menu-modal__bg_4 {
        transition-delay: .2s;
    }

    .menu-modal__bg_5 {
        top: 80%;
        transition-delay: .52s;
    }

    .menu-modal_shown .menu-modal__bg_5 {
        transition-delay: .22s;
    }

    .menu-modal_shown .menu-modal__bg {
        transform: translate(0, 0);
    }

    @media (max-width: 767px) {
        .menu-modal {
            background-position: -6px -381px;
            background-size: 1070px auto;
        }
    }

    .menu-modal_shown {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    .menu-modal__wrapper {
        padding: 139px 36px 197px;
    }

    @media (max-width: 767px) {
        .menu-modal__wrapper {
            padding: 75px 24px;
        }
    }

    .menu-modal__list {
        max-height: calc(100vh - 336px);
        padding-left: 56px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        opacity: 0;
        visibility: hidden;
        transform: translate(-60px, 0);
        transition: all .3s ease;

    }

    .menu-modal_shown .menu-modal__list {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
        transition-delay: .3s;
    }

    @media (max-width: 767px) {
        .menu-modal__list {
            max-height: calc(100vh - 150px);
            padding-left: 0;
        }
    }

    .menu-modal__list-item {
        margin-right: 111px;
        flex: 0 1 auto;
    }

    @media (max-width: 767px) {
        .menu-modal__list-item {
            margin-right: 39px;
        }
    }

    .menu-modal__list-link {
        font-size: 2.5rem;
        color: #fff;
        letter-spacing: 0;
        line-height: 2;
        font-weight: 300;
        text-decoration: none;
        transition: all .3s ease;
    }

    .menu-modal__list-link:hover {
        color: #000;
    }

    @media (max-width: 767px) {
        .menu-modal__list-link {
            font-size: 1.5rem;
            line-height: 1.5;
        }
    }

    .menu-modal__logo {
        background: url("./../assets/img/logo_white.svg") no-repeat;
        background-size: 100%;
        width: 39px;
        height: 34px;
        margin: auto;
        position: absolute;
        top: 27px;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease .1s;
    }

    .menu-modal_shown .menu-modal__logo {
        opacity: 1;
        visibility: visible;
    }

    @media (max-width: 767px) {
        .menu-modal__logo {
            top: 16px;
        }
    }


</style>

<template>
    <div class="footer">
        <Sound />
    </div>
</template>

<script>

    import Sound from './Sound';

    export default {
        data() {
            return {}
        },
        components: {
            Sound,
        }

    }
</script>

<style>
    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }

    .footer:before {
        content: '';
        background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 1;
        visibility: visible;
        pointer-events: none;
    }

    @media (max-width: 767px) {
        .footer:before {
            right: 0;
        }
    }




</style>

import Vue from 'vue';


import App from './App.vue';
window.$ = require('jquery');
window.JQuery = require('jquery');
window.fullpage = require('./assets/js/jquery.fullpage.js');

window.fbAsyncInit = function () {
    FB.init({
        appId: '2002507793381009',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.10'
    });
    FB.AppEvents.logPageView();
};

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

Vue.config.productionTip = false;
import store from './store';

new Vue({
    render: h => h(App),
    store
}).$mount('#app');

<template>
    <div id="app"   v-bind:class="{'page_scrolled': active, 'page_with-photo-modal': photoModal.show}">
        <Page />
    </div>
</template>



<script>
    import Page from './components/Page.vue';

    export default {
        name: 'app',
        components: {
            Page
        },
        computed: {
            currentPage() {
                return this.$store.getters.currentPage;
            },
            photoModal() {
                return this.$store.getters.photoModal;
            }
        },
        watch: {
            currentPage(currentPage, oldPage) {
                if (oldPage.indexOf('/photos/') === -1) {
                    this.$store.dispatch('changeOldPage', oldPage);
                }

                if ((currentPage === 'main' || currentPage === 'photos' || currentPage === 'photography')
                    && (oldPage === 'main' || oldPage === 'photos' || oldPage === 'photography'))
                    {
                    return
                }
                location.hash = currentPage;
            }
        },
        data() {
            return {
                active: false
            }
        },
        methods: {
            initScrollEvent() {
                let scrollTimeout;
                window.addEventListener('scroll', () => {
                    scrollTimeout = setTimeout(() => this.toggleSlideActive(window.pageYOffset));
                });
            },
            toggleSlideActive(pageYOffset) {
                this.active = pageYOffset > 10;
            }
        },
        mounted() {
            this.initScrollEvent();
            this.toggleSlideActive(window.pageYOffset);
        }
    }
</script>

<style>

    @import "./assets/css/fullpage.css";

    @font-face {
        font-family: 'AlbertusNova';
        src: url('./assets/fonts/AlbertusNova/AlbertusNova-Thin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Thin.woff') format('woff'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Thin.ttf')  format('truetype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Thin.svg#AlbertusNova-Thin') format('svg');
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: 'AlbertusNova';
        src: url('./assets/fonts/AlbertusNova/AlbertusNova-Black.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Black.woff') format('woff'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Black.ttf')  format('truetype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Black.svg#AlbertusNova-Black') format('svg');
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: 'AlbertusNova';
        src: url('./assets/fonts/AlbertusNova/AlbertusNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Bold.woff') format('woff'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Bold.ttf')  format('truetype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Bold.svg#AlbertusNova-Bold') format('svg');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'AlbertusNova';
        src: url('./assets/fonts/AlbertusNova/AlbertusNova-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Light.woff') format('woff'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Light.ttf')  format('truetype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Light.svg#AlbertusNova-Thin') format('svg');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'AlbertusNova';
        src: url('./assets/fonts/AlbertusNova/AlbertusNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Regular.woff') format('woff'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Regular.ttf')  format('truetype'),
        url('./assets/fonts/AlbertusNova/AlbertusNova-Regular.svg#AlbertusNova-Regular') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    html {
        height: 100%;
        overflow-y: scroll;
        font-size: 62.5%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    }

    body {
        background: #000;
        height: 100%;
        color: #000;
        font-size: 1.2rem;
        line-height: 1.3;
        text-align: left;
        word-wrap: break-word;
    }

    input, button, select, textarea, html {
        font-family: AlbertusNova, sans-serif;
    }

    a, button {
        cursor: pointer;
    }

    p {
        margin: 0 0 1.5em;
    }

</style>
